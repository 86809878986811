import React from 'react';
import BackgroundImage from './BackgroundImage';
import ContentContainer from './ContentContainer';
import NewsletterForm from './NewsletterForm';
import { ReactTyped } from "react-typed";
import grid from '../grid.jpg'


const ImageGenerationHero: React.FC = () => {
  return (
    <main className="flex overflow-hidden flex-col pb-60 bg-white max-md:pb-24">
      <section className="flex relative flex-col items-start px-11 pt-24 pb-14 w-full min-h-[512px] max-md:px-5 max-md:pt-24 max-md:max-w-full">
        <BackgroundImage src={grid} />
        <div className='flex justify-between w-full' style={{position: 'relative'}} >
          <ContentContainer />
          <a
            className="overflow-hidden max-h-12 gap-2 p-3 self-stretch rounded-lg border border-solid bg-[#333333] border-[#333333] text-neutral-100 max-sm:mx-auto z-[1000]"
            href="https://app.fabbox.tech"
          >
            Go to App
          </a>
        </div>
        <div className='flex flex-wrap'>
          <h1 className="max-w-full relative pl-4 pt-4 text-5xl tracking-tighter text-[#333333] bg-white bg-opacity-20 rounded-[50px] max-md:pl-3 max-md:max-w-full max-md:text-4xl">
            Turn your ideas into images,
            <ReactTyped
              className="flex-shrink-0 whitespace-nowrap relative px-4 py-4 text-5xl tracking-tighter text-[#333333] bg-white bg-opacity-20 rounded-[50px] max-md:px-3 max-md:max-w-full max-md:text-4xl"
              strings={[
                " effortlessly.",
                " accurately.",
                " consistently."
              ]}
              typeSpeed={40}
              backSpeed={80}
              backDelay={2000}
              loop
            />
          </h1>
        </div>
      </section>
      <NewsletterForm />
    </main>
  );
};

export default ImageGenerationHero;