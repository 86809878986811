import React from "react";
import ImageGenerationHero from "./components/ImageGenerationHero";
import Privacy from "./Policies/Privacy";
import TermsAndConditions from "./Policies/TermsAndConditions";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Return from "./Policies/Return";

function App() {
  const [showPrivacy, setShowPrivacy] = React.useState(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <ImageGenerationHero />
            <div className="justify-center flex">
              <a href="/privacy" className="text-gray-600 underline hover:text-gray-800 hover:cursor-pointer mt-8 mb-8 block p-2">Privacy Policy</a>
              <a href="/terms" className="text-gray-600 underline hover:text-gray-800 hover:cursor-pointer mt-8 mb-8 block p-2">Terms and Conditions</a>
              <a href="/return" className="text-gray-600 underline hover:text-gray-800 hover:cursor-pointer mt-8 mb-8 block p-2">Return Policy</a>
            </div>
          </>
        } />
        {
          [[<Privacy />, '/privacy'], [<TermsAndConditions />, 'terms'], [<Return/>, '/return']].map((Component, index) => (
            <Route path={Component[1]} element={
              <>
                <a href="/" className="block mt-8 mb-8 px-4 py-2 bg-blue-500 text-white rounded text-center cursor-pointer" style={{ display: 'block', margin: '2rem' }}>Back</a>
                <div className="justify-center p-10">
                  {Component[0]}
                </div>
              </>
            } />
          ))
        }
      </Routes>
    </Router>
  );
}

export default App;
